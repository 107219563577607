<template>
  <v-app id="inspire">
    <Header v-model="expandOnHover" @input="changeExpandHover"></Header>

    <v-content>
      <v-container fluid class="page-wrapper" id="idcontent">
        <router-view />
        <v-btn bottom color="primary" dark fab fixed right @click.stop="setCustomizerDrawer(!Customizer_drawer)">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </v-container>
    </v-content>
    <Sidebar :expand-on-hover.sync="expandOnHover"></Sidebar>
    <Customizer v-model="expandOnHover"></Customizer>

    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import Footer from './footer/Footer';
import Customizer from './customizer/Customizer';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Layout',

  components: {
    Header,
    Sidebar,
    Footer,
    Customizer
  },

  props: {
    source: String
  },
  data: () => ({
    expandOnHover: false
  }),
  computed: {
    ...mapState(['Customizer_drawer', 'Expand_nav'])
  },

  methods: {
    ...mapMutations({
      setCustomizerDrawer: 'SET_CUSTOMIZER_DRAWER',
      changeExpandHover: 'CHANGE_EXPAND_NAV',
      setWidthBreadcrumb: 'SET_WIDTH_BREADCRUMB'
    })
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$vuetify.breakpoint.smAndDown) this.setWidthBreadcrumb(window.innerWidth);
      else if (this.Expand_nav) this.setWidthBreadcrumb(window.innerWidth - 280);

      window.addEventListener('resize', () => {
        const content = document.getElementById('idcontent');
        this.setWidthBreadcrumb(content.offsetWidth);
      });
    });
  }
};
</script>

<style></style>
