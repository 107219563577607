export default [
  {
    group: '/administracion',
    model: false,
    // icon: 'mdi-account',
    avatar: require('@/assets/images/elections.svg'),
    title: 'ADMINISTRACIÓN',
    meta: {
      code: '*'
    },
    children: [
      {
        icon: 'mdi-account-box-multiple-outline',
        title: 'Usuarios',
        to: 'matriz_usuarios',
        meta: {
          code: '*'
        }
      },
      {
        icon: 'mdi-ballot-outline',
        title: 'Campañas',
        to: 'matriz_campana',
        meta: {
          code: '*'
        }
      },
      {
        icon: 'mdi-account-hard-hat',
        title: 'Trabajadores',
        to: 'matriz_trabajador',
        meta: {
          code: '*'
        }
      }
    ]
  },
  {
    group: '/encuesta',
    model: false,
    // icon: 'mdi-account',
    avatar: require('@/assets/images/elections.svg'),
    title: 'ENCUESTAS',
    meta: {
      code: '*'
    },
    children: [
      {
        icon: 'mdi-developer-board',
        title: 'Formatos Encuesta',
        to: 'matriz_formato_encuesta',
        meta: {
          code: '*'
        }
      },
      {
        icon: 'mdi-text-box-multiple-outline',
        title: 'Encuestas Asignadas',
        to: 'matriz_encuestas_asignadas',
        meta: {
          code: '*'
        }
      }
    ]
  }
];
