class HeaderService {
  constructor() {}
  resources = () => ({
    cerrarSesion: {
      uri: `/v1/logout`,
      method: ['get']
    }
  });
}

export default HeaderService;
