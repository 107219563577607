<template>
  <v-app-bar app clipped-left clipped-right color="primary" dark>
    <!---Logo part -->
    <v-toolbar-title class="align-center d-flex">
      <span class="logo-icon mt-2">
        <!-- <img width="50" src="../../../assets/images/logo-gcyc.svg" />-->
      </span>
      <span class="logo-text ml-2" :class="`${showLogo ? '' : 'hidelogo'}`">
        <span class="mt-2"> GQUEST </span>
      </span>
    </v-toolbar-title>
    <!---Logo part -->
    <!---/Toggle sidebar part -->
    <div @click="showhideLogo">
      <v-app-bar-nav-icon
        @click="
          funSidebar();
          funStateSidebar();
        "
      />
    </div>
    <!---/Toggle sidebar part -->
    <!---Search part -->
    <!-- <v-btn dark icon class="mr-1 d-sm-block d-none" @click="searchbox">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <div v-if="showSearch" class="searchinput primary">
      <template>
        <v-text-field
          placeholder="Buscar"
          class="mt-3 mb-0"
          append-icon="mdi-close"
          @click:append="searchbox"
        ></v-text-field>
      </template>
    </div> -->
    <!---/Search part -->
    <v-spacer />
    <!---right part -->
    <!---Notification -->
    <!--    <v-menu bottom left offset-y origin="top right" transition="scale-transition">-->
    <!--      <template v-slot:activator="{ on }">-->
    <!--        <v-btn dark icon v-on="on" class="mr-2">-->
    <!--          <v-badge color="red" dot :value="showBadge">-->
    <!--            <v-icon>mdi-bell</v-icon>-->
    <!--          </v-badge>-->
    <!--        </v-btn>-->
    <!--      </template>-->
    <!--      <v-list>-->
    <!--        <h4 class="px-5 py-3 pt-2 font-weight-medium title">Notificaciones</h4>-->
    <!--        <v-divider></v-divider>-->
    <!--        <v-list-item-->
    <!--          @click.prevent="updateNotificationVisited(item)"-->
    <!--          :class="{ 'blue lighten-5': !item.visited }"-->
    <!--          v-for="(item, i) in notifications"-->
    <!--          :key="i"-->
    <!--        >-->
    <!--          <v-list-item-title>-->
    <!--            <div class="d-flex align-center py-3 ">-->
    <!--              <div class>-->
    <!--                <v-btn class="mr-3" depressed fab small dark :color="item.iconbg">-->
    <!--                  <v-icon v-if="item.ticon" dark>{{ item.icon }}</v-icon>-->
    <!--                  <img v-else :src="item.icon" width="30" />-->
    <!--                </v-btn>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <h4 class="font-weight-medium mb-1">{{ item.title }}</h4>-->
    <!--                <span class="text&#45;&#45;secondary caption descpart d-block" style="white-space: pre-line" v-html="item.desc">-->
    <!--                </span>-->
    <!--                <small class="text&#45;&#45;secondary">{{ item.time | convertDates }}</small>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </v-list-item-title>-->
    <!--        </v-list-item>-->
    <!--      </v-list>-->
    <!--    </v-menu>-->
    <!---Notification -->
    <!---Messages -->
    <!--<v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-2">
          <v-badge color="red" dot>
            <v-icon>mdi-bell</v-icon>
            <v-icon>mdi-message</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list>
        <h4 class="px-5 pt-2 py-3 font-weight-medium title">Messages</h4>
        <v-divider></v-divider>
        <v-list-item v-for="(item, i) in messages" :key="i" @click="href">
          <v-list-item-title>
            <div class="d-flex align-center py-3">
              <div class="mr-3">
                <v-badge bordered bottom :color="item.avatarstatus" dot offset-x="10" offset-y="10">
                  <v-avatar>
                    <img
                      :src="
                        'https://www.wrappixel.com/demos/admin-templates/materialpro-bootstrap-latest/material-pro/src/assets/images/users/' +
                          item.avatar +
                          '.jpg'
                      "
                      :alt="item.title"
                    />
                  </v-avatar>
                </v-badge>
              </div>
              <div>
                <h4 class="font-weight-medium">{{ item.title }}</h4>
                <span class="text--secondary caption descpart d-block text-truncate">{{ item.desc }}</span>
                <small class="text--secondary">{{ item.time }}</small>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>-->
    <!---Messages -->
    <!---User -->
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-avatar size="40">
            <v-icon>fas fa-cog</v-icon>
            <!-- <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" /> -->
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(item, i) in userprofile" :key="i" @click="menuUsuario(item.id)">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!---User -->
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations } from 'vuex';
import HeaderService from './services/HeaderService';
import moment from 'moment';
moment.locale('es');

export default {
  name: 'Header',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showBadge: false,
    headerService: null,
    showLogo: true,
    showSearch: false,
    notifications: [],
    first: true,
    /*messages: [
      {
        title: 'Sonu Nigam',
        avatar: '1',
        avatarstatus: 'success',
        desc: 'Singing Show tonigh at 9pm!',
        time: '9:30AM'
      },
      {
        title: 'Sonu Nigam',
        avatar: '2',
        avatarstatus: 'error',
        desc: 'The greate malody songs ever sung',
        time: '10:30AM'
      },
      {
        title: 'Arijit singh',
        avatar: '3',
        avatarstatus: 'warning',
        desc: 'You can customize this template as you want',
        time: '11:30AM'
      },
      {
        title: 'Pavan Kumar',
        avatar: '4',
        avatarstatus: 'success',
        desc: 'Sent you an notification',
        time: '12:30AM'
      }
    ],*/
    userprofile: [
      //{ title: 'My Profile' },
      //{ title: 'My Balance' },
      //{ title: 'Inbox' },
      //{ title: 'Account Setting' },
      { id: 'CS', title: 'Cerrar Sesión' }
    ],
    sidebarDrawer: false
  }),

  computed: {
    ...mapState(['Sidebar_drawer'])
  },
  watch: {
    notifications(val) {
      this.showBadge = false;
      for (const object of val) {
        if (!object.visited) {
          this.showBadge = true;
          break;
        }
      }
    }
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: 'SET_SIDEBAR_DRAWER'
    }),
    showhideLogo: function() {
      this.showLogo = !this.showLogo;
    },
    searchbox: function() {
      this.showSearch = !this.showSearch;
    },
    funSidebar() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.setSidebarDrawer(!this.Sidebar_drawer);
        return;
      }
      this.$emit('input', !this.value);
      return;
    },
    funStateSidebar() {
      console.log('funstatesidebar');
    },
    menuUsuario(id) {
      switch (id) {
        case 'CS':
          this.cerrarSesion();
          break;
      }
    },
    async cerrarSesion() {
      const res = await this.headerService.get().execResource('cerrarSesion');
      if (res.status) {
        localStorage.removeItem('led17h9p2x');
        localStorage.removeItem('nc4rhtcc976');
        localStorage.removeItem('k3rtxqdp234');
        localStorage.removeItem('oq1rcizm635');
        localStorage.removeItem('rte459h6v5s');
        //localStorage.removeItem('iow5csjr398');
        localStorage.removeItem('ppw3kkll432');
        localStorage.removeItem('mko9lers935');
        localStorage.removeItem('ust0tare321');
        localStorage.removeItem('jst7uers823');
        localStorage.removeItem('oko3ldkd543');
        localStorage.removeItem('myt2jdju876');
        localStorage.removeItem('pop2utts921');

        await this.$router.push({ path: '/auth/login' });
      }
    }
  },
  filters: {
    convertDates(val) {
      return moment(val).calendar();
    }
  },
  async mounted() {
    this.headerService = this.$httpService(new HeaderService(), this);
  }
};
</script>

<style lang="scss">
.v-application #main-sidebar.theme--dark.white {
  background: #363636 !important;
}
.hidelogo {
  display: none;
}
.searchinput {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descpart {
  max-width: 220px;
}
</style>
